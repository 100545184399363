import React, { useState } from 'react'
import { Link, redirect } from 'react-router-dom'
import QrCodeDownloadPopup from '../../components/popup/qrCodeDownloadPopup'
import { getMyInfoQuery, memberAppQuery } from '../../../queries/memberQueries'
import { useQueries } from '@tanstack/react-query'
import QrCodePopup from '../../components/popup/qrCodePopup'
import { copyEvent } from '../../../hooks/useEvent'
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import { ACCESS_TOKEN } from '../../../const/const'

/**
 * 해당 페이지가 랜더링 되기전 Loader가 실행되어 데이터 통신을 마친후 페이지를 랜더링 해준다.
 * @param {QueryClient} queryClient router.js에서 받아온 React-query Client
 * @returns 
 */
export const getMyInfoLoader =
    (queryClient) => async () => {
        const json = await queryClient.fetchQuery(getMyInfoQuery());
        if (json.result !== 'SUCCESS') {
            localStorage.removeItem(ACCESS_TOKEN);
            alert("로그인 후 이용해주세요.");
            return redirect("/login");
        }
        await queryClient.fetchQuery(memberAppQuery());
        return json;
    }

const Mypage = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [qrIsOpen, setQrIsOpen] = useState(false);
    const [{ data: { data } }, { data: { data: memberAppData } }] = useQueries({
        queries: [getMyInfoQuery(), memberAppQuery()]
    })
    const [isCopied, setIsCopied] = useState(false);



    // 가장 마지막에 만든 하나의 앱만 보여줌.
    const appData = memberAppData[memberAppData.length - 1];
    // const appData = {};

    return (
        <>
        <Helmet>
            <title>AiAppㅣ마이페이지</title>
            <meta name="author" content="mBaaS Inc." />
            <meta name="description" content="마이페이지입니다. 회원 정보 관리, 생성된 앱 목록, 서비스 설정 등을 확인하고 개인화된 서비스를 이용하세요."/>
            <meta name="keywords" content="AiApp, 앱 생성, 코딩, 앱 빌드, 앱 창업, 앱 개발, 엠바스, 에이아이앱, 앱만드는방법"/>
            <meta property="og:type" content="website" />
            <meta property="og:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
            <meta property="og:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
            <meta property="og:url" content="https://aiapp.help/" />
            <meta property="og:images" content="../../../../public/img/opengraph.jpg" />
            <meta property="twitter:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
            <meta property="twitter:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
            <meta property="twitter:image" content="../../../../public/img/opengraph.jpg"/>
            <meta property="twitter:url" content="https://aiapp.help/" />
            <meta property="twitter:card" content="summary_large_image" />
        </Helmet>
            <QrCodeDownloadPopup isOpen={isOpen} setIsOpen={setIsOpen} />
            <QrCodePopup isOpen={qrIsOpen} setIsOpen={setQrIsOpen} value={appData?.apkPath ?? ""} />
            <main>
                <div className="mypageWrap height10 bg1">
                    <div className="width14">
                        <h2 className='Tit' data-aos="fade-up" data-aos-duration="2000" >My Page</h2>
                        <div className="grid">
                            <div className="item item1" data-aos="fade-up" data-aos-duration="2000">
                                <div className="d-flex gap20 align-items-center">
                                    <div className="profile"></div>
                                    <div className="user">
                                        <h3><span>{data?.memberName}</span> 님</h3>
                                        <p>{data?.memberEmail}</p>
                                    </div>
                                </div>
                                <div>
                                    <h3>무료 사용기간</h3>
                                    <p>{appData?.freeUseAt ? new Date(appData?.freeUseAt).toLocaleDateString() + "에 기간종료 됨" : "-"}</p>
                                </div>
                                {/* <div>
                                    <h3>유료 사용기간</h3>
                                    <p>2024.03.13 ~ 2025.03.13 (잔여일 365일)</p>
                                </div> */}
                                <Link to={"user"}><button className="editBtn">내정보 수정</button></Link>
                            </div>
                            <div className="item item2" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="100">
                                <h3>내가 생성한 앱</h3>
                                {
                                    memberAppData.length !== 0 ? <div>
                                        <div className="item2Con">
                                            <div className="d-flex">
                                                <div className="d-flex align-items-center gap10">
                                                    <h4>앱 이름</h4>
                                                    <p>{appData?.appName ?? "-"}</p>
                                                </div>
                                                {/* <div className="d-flex align-items-center gap10">
                                                    <h4>앱 최근 수정일</h4>
                                                    <p>2024.03.20 12:35 수정</p>
                                                </div> */}
                                            </div>
                                            <div className="d-flex">
                                                <div className="d-flex align-items-center gap10">
                                                    <h4>프로젝트 명</h4>
                                                    <p>{appData?.appProjectName ?? "-"}</p>
                                                </div>
                                                {/* <div className="d-flex align-items-center gap10">
                                                    <h4>기획서 최종 제출일</h4>
                                                    <p>2024.03.21 17:02 제출</p>
                                                </div> */}
                                            </div>
                                            <div className="d-flex">
                                                <div className="d-flex align-items-center gap10">
                                                    <h4>Key</h4>
                                                    <p className='keyvalue'>{appData?.appApiKey ?? "-"}</p>
                                                    {appData?.appApiKey && (<button onClick={() => copyEvent(setIsCopied, appData.appApiKey)} className="copyBtn copyBtn-sm copyBtn-filled copyBtn-primary ml-2 align-items-center">
                                                        <span className="copyBtn-label-wrap">
                                                            <span className="copyBtn-node start-node">
                                                                {isCopied ? (

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" fill='currentColor' viewBox='0 0 24 24'>
                                                                        <path fill-rule="evenodd" d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" clip-rule="evenodd"></path>
                                                                    </svg>
                                                                ) : (
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" fill='currentColor' viewBox='0 0 24 24'>
                                                                        <path fill-rule="evenodd" d="M7 5a3 3 0 0 1 3-3h9a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3h-2v2a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3v-9a3 3 0 0 1 3-3h2V5Zm2 2h5a3 3 0 0 1 3 3v5h2a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h-9a1 1 0 0 0-1 1v2ZM5 9a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1v-9a1 1 0 0 0-1-1H5Z" clip-rule="evenodd"></path>
                                                                    </svg>
                                                                )
                                                                }
                                                            </span>
                                                            <span className="copyBtn-label-inner">Copy</span>
                                                        </span>
                                                    </button>)
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                        <div className="item2Btn">
                                            <Link to={`/plan?appCode=${appData?.appCode}`}><button>기획서 관리</button></Link>

                                            <a href="https://church.aiapp.link/login" target="_blank" rel="noopener noreferrer">
                                                <button>관리자 페이지</button>
                                            </a>
                                        </div>
                                    </div> : <div>
                                        <div className="item2Con nodata">
                                            <h4>생성된 앱이 없습니다.</h4>
                                        </div>
                                        <div className="item2Btn">
                                            <Link to={"/checklist"}><button>앱 생성하러가기</button></Link>
                                        </div>
                                    </div>
                                }
                            </div>
                            {
                                memberAppData.length !== 0 ?
                                    <div className="item item3" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="200">
                                        <div>
                                            <div className="d-flex align-items-center gap10">
                                                <h3>앱 다운로드 QR</h3>
                                                <div className="info" onClick={() => { setIsOpen(true) }}></div>
                                            </div>
                                            <p>현재 안드로이드 기반 OS 에서만 테스트 가능합니다.</p>
                                        </div>
                                        <div className="QRBtnWrap">
                                            {/* <div className="QRWrap">
                                                <img src="../img/qr.png" alt="QR코드 이미지" />
                                            </div> */}
                                            <button className="QRBtn" onClick={() => { setQrIsOpen(true) }}>앱 다운로드 QR코드 보기</button>
                                        </div>
                                        {/* <div className="nodata">생성한 앱이 없습니다</div> */}
                                    </div> : ""
                            }
                            {/* <div className="item item4" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="300">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h3>내 문의함</h3>
                                    <button>1:1 문의하러가기 {">"}</button>
                                </div>
                                <div>
                                    <ul className="inquiryList">
                                        <Link to={"/inquiry/12"}>
                                            <li className="d-flex justify-content-between align-items-center">
                                                <h4>문의 드립니다.</h4>
                                                <div className="d-flex align-items-center gap40">
                                                    <p>yyyy.mm.dd</p>
                                                    <div className="staus">답변대기</div>
                                                </div>
                                            </li>
                                        </Link>
                                        <li className="d-flex justify-content-between align-items-center">
                                            <h4>문의 드립니다.</h4>
                                            <div className="d-flex align-items-center gap40">
                                                <p>yyyy.mm.dd</p>
                                                <div className="staus on">답변완료</div>
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="pageWrap">
                                        <ul>
                                            <li><img src="../img/icon_arrow_leftW.png" alt="페이지 왼쪽 화살표 이미지" /></li>
                                            <li className="on">1</li>
                                            <li>2</li>
                                            <li>3</li>
                                            <li>4</li>
                                            <li>5</li>
                                            <li><img src="../img/icon_arrow_rightW.png" alt="페이지 오른쪽 화살표 이미지" /></li>
                                        </ul>
                                    </div> */}
                            {/* </div> */}
                            {/* <div className="nodata">문의내역이 없습니다.</div> */}
                            {/* </div> */}
                        </div>
                    </div>


                </div>

            </main>
            <ToastContainer
                position="top-center" // 알람 위치 지정
                autoClose={3000} // 자동 off 시간
                hideProgressBar={false} // 진행시간바 숨김
                closeOnClick // 클릭으로 알람 닫기
                theme="colored"
            />
        </>
    )
}

export default Mypage